
.modal-header .close {
  border-style: none;
  background-color: white;
  margin-right: 8px;
  font-size: 30px;
  align-self: center;
  padding: 14px;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.modal-dialog {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0.5rem;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  margin: 0 auto;
  transform: translateY(-50%);
  top: 50%;
}

.modal.fade .modal-dialog {
  opacity: 0;
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  opacity: 1;
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px;
  box-shadow: 0px 24px 38px rgba(58, 92, 144, 0.14), 0px 9px 46px rgba(58, 92, 144, 0.12), 0px 11px 15px rgba(58, 92, 144, 0.2);
  border-radius: 8px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  transition: opacity 0.3s;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.25;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 2px solid #DDE1E6;
}

.modal-title {
  align-self: center;
  padding-left: 1.5rem;
}

.modal-title.h4 p strong {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #002846;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-bottom: 25px;
  padding: 1.5rem;
  height: 400px;
  overflow-y: auto;
}

.modal-body:after {
  display: none;
}

.modal-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.modal-body::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #75899C; 
  border-radius: 4px;
}

.modal-body p {
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
}

.modal-body p strong {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #002846;
}

.modal-body li::marker {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #002846;
}

.modal-body ol li strong {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #002846;
}

.modal-body ol ul li {
  color: #75899c;
}

.modal-body ol td {
  color: #75899c;
}

.modal-body ol td b{
  color: #002846;
}

.modal-body ol ul li b {
  color: #002846;
}

.modal-body th {
  color: #002846;
}

.modal-body ul li {
  color: #75899c;
}

.modal-body ul li strong {
  color: #002846;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }

  .modal-body {
    height: 500px;
  }

  .modal-dialog {
    max-width: 600px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
