html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

footer {
  z-index: 9;
  position: absolute;
  bottom: 1.75rem;
  left: 0;
  right: 0;
  padding: 12px 22px;
}

@media only screen and (min-width: 380px) {
  footer {
    bottom: 5rem;
  }
}
hr {
  height: 1px;
  /* color: rgb(191, 230, 184);
  background-color: rgb(191, 230, 184); */
  border: none;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="file"] {
  display: none;
}

.column {
  flex-flow: row wrap;
  flex: 1 100%;
  align-items: center;
  flex-direction: column;
  flex-basis: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 71px;
}

.row-justify-space {
  justify-content: space-between;
}

.row-justify-center {
  justify-content: center;
}

.row-justify-space button {
  width: 48%;
}

.row svg {
  margin: 15px;
}

@media only screen and (min-height: 1400px) {
  .img-preview {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1300px) {
  footer {
    bottom: 0rem;
  }
}

@media only screen and (min-width: 1024px) and (min-height: 900px) {
  footer {
    bottom: 0.75rem;
  }
}

@media only screen and (max-height: 1400px) {
  .img-preview {
    width: 60%;
    display: block;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 380px) {
  h2 {
    margin-bottom: 0 !important;
    font-size: 1.75em !important;
  }
}
.video-container {
  z-index: 0;
  position: absolute;
  margin-top: 52px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 52px;
  display: flex;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.75);
}

.capture-canvas {
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}
.overlay-canvas {
  opacity: 0.7;
  z-index: 2;
}
.calculating-canvas {
  position: absolute;
  z-index: 1;
}

.overlay-canvas {
  z-index: 2;
}

.instruction_gif {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 0;
  display: flex;
  width: 350px;
  height: 350px;
}

.flip-x {
  transform: scaleX(-1);
}

#face_wrapper #react-modal-image-img {
  transform: translate3d(-50%, -50%, 0px) rotateY(180deg) !important;
}

.f3d-animation {
  z-index: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
}

.ui-canvas {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.progress {
  display: flex;
  height: 1.5rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  z-index: 200;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
  z-index: 200;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.bg-success {
  background-color: blue !important;
}

.bg-warning {
  background-color: grey !important;
}

.landscape-safari {
  height: max-content;
  width: auto;
  z-index: -1;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
}

.others-landscape {
  width: 100%;
  height: auto;
  z-index: -1;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
}

.overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@media screen and (orientation: landscape) {
  .overlay-rect {
    -webkit-mask-size: 100vw 83vh;
    mask-size: 100vw 83vh;
  }
}
@media screen and (orientation: portrait), (min-width: 992px) {
  .overlay-rect {
    -webkit-mask-size: cover;
    mask-size: cover;
  }
}

.overlay-oval:before {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 100%;
  width: 62vw;
  height: 75vw;
  box-shadow: 0px 0px 0px 100vw rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease-in;
}

.overlay-oval::after {
  content: " ";
  position: absolute;
  width: 62vw;
  height: 75vw;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 5px solid #f16063;
  z-index: 15;
  border-radius: 50%;
}

@media screen and (orientation: portrait) and (max-width: 400px) {
  .overlay-oval:before {
    width: 60vw;
    height: 73vw;
  }

  .overlay-oval::after {
    width: 60vw;
    height: 73vw;
  }
}

@media screen and (orientation: portrait) and (max-width: 320px) {
  .overlay-oval:before {
    width: 54vw;
    height: 68vw;
  }

  .overlay-oval::after {
    width: 54vw;
    height: 68vw;
  }
  footer {
    bottom: -0.5rem;
  }
}

.success .overlay-oval:after,
.pending .overlay-oval:after,
.success .indicator,
.pending .indicator {
  display: none !important;
}

.success .overlay-oval:before {
  margin-left: 0;
  top: 0;
  left: 0;
  -webkit-transform: none;
  transform: none;
  border-radius: 0%;
  width: 105vw;
  height: 105vh;
}

.success footer,
.pending footer {
  display: none !important;
}

.face-passed.overlay-oval::after {
  border: 5px solid #66cb9f;
}

.face-found.overlay-oval::after {
  border: 5px solid #fac109;
}

.w-full {
  width: 100%;
}

.m-20 {
  margin: 20px;
}
.m-15 {
  margin: 15px;
}

.box-shadow {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  -webkit-appearance: none;
  -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}

.message-box {
  width: 100%;
  border-radius: 3px;
  background-color: white;
  color: rgba(54, 64, 69, 1);
  font-size: 2.2vh;
  line-height: 20px;
  text-align: center;
}

.message-box-error {
  color: rgb(219, 49, 6);
}

.scale-edge {
  transform: scale(-2, 2.4) !important;
}

.hiddenEdge {
  overflow: hidden;
}

._loading_overlay_wrapper {
  height: 105%;
  height: calc(100% + 30px);
}

._loading_overlay_content {
  user-select: none;
  font-weight: 500;
  font-size: 0.9em !important;
}

._loading_overlay_overlay {
  position: absolute;
  background: rgb(34, 34, 34, 0.8) !important;
}
.react-transform-component {
  display: block !important;
  text-align: center;
}
#qrCode {
  border: 3px solid white;
}
