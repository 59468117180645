@media (min-aspect-ratio: 3/4) {
  .overlay-oval:before {
    width: 42vh;
    height: 52vh;
  }

  .overlay-oval::after {
    width: 42vh;
    height: 52vh;
  }

  footer {
    width: 70%;
    margin: 0 auto;
    padding: 15px;
  }
}

@media (min-width: 1100px) {
  .overlay-oval:before {
    width: 52vh;
    height: 65vh;
  }

  .overlay-oval::after {
    width: 52vh;
    height: 65vh;
  }
}
