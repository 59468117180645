.panel {
  display: flex;
  /* border: 1px solid;	 */
  border-radius: 5px;
  align-items: center;
  height: auto;
  margin-bottom: 1rem;
}

.panel p {
  margin-right: 15px;
}

.panel-intro {
  width: 50%;
  display: inline-block;
}

.border-green {
  border: solid 1px rgb(191, 230, 184);
}

.border-gray {
  border: solid 1px rgb(220, 225, 227);
}
.bg-yellow {
  background-color: #fcf8d9;
}

.bg-gray {
  background-color: rgb(248, 249, 249);
}

.bg-green {
  background-color: #e8f2e6;
}

.bg-blue {
  background-color: #e3f5fd;
}

.bg-red {
  background-color: #ffece6;
}
