.margin-h-auto {
  margin-left: auto;
  margin-right: auto;
}

.img-document-example {
  height: 160px;
  display: block;
  margin: 15px auto;
}

.icon-passport-example {
  display: block;
  margin: 15px auto;
}

html {
  min-height: 100%;
}

.header {
  display: flex;
  justify-content: center;
}

.daon-image {
  align-self: center;
  width: 50%;
  height: 50%;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

.checkbox {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  margin-right: 40px;
}

#daon_image {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}

.container_sectionHeader {
  width: auto;
  max-width: 680px;
  padding: 15px 15px;
}

/* Custom page CSS
  -------------------------------------------------- */
/* Not required for template or sticky footer method. */

.centered-form .panel {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.3) 20px 20px 20px;
}

.card-header {
  background-color: black;
}
.sr-only {
  font-size: 0px;
}
.label-font-size {
  font-size: 1.5em;
}

.btn {
  margin-top: 5%;
}
